/*
* ハンバーガーメニュー
* -------------------------------------------------------------------
*/
$(function() {
  $('.nav-header__toggle').click(function() {
      $(this).toggleClass('active');

      if ($(this).hasClass('active')) {
          $('.spnav-header').addClass('active');// ナビを開くときの処理
          $("html").addClass("is-fixed"); //背景固定！
      } else {
          $('.spnav-header').removeClass('active');// ナビを閉じるときの処理！
          $("html").removeClass("is-fixed");// 背景固定解除！
      }
  });
});

/*
* トップページのswiper
* -------------------------------------------------------------------
*/
const swiper = new Swiper('.top-slider', {
  // スライドメニューの表示方法
  direction: 'horizontal',
  loop: true,
  autoHeight: true,
  speed: 2000,
  effect: 'fade',
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
  // 「次へ」や「戻る」のアイコン表示
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});


/*
* トップページのセレクトボックス（検索窓）
* -------------------------------------------------------------------
*/
$(function () {
  $('.search-select1').multipleSelect()
});

/*
* トップへ戻るボタン
* -------------------------------------------------------------------
*/
$(document).ready(function () {
  $(".nav-pagetop__topbtn").hide();　//ボタンを非表示にする
  $(window).on("scroll", function () {
      if ($(this).scrollTop() > 200) { //ページの上から200pxスクロールした時
          $(".nav-pagetop__topbtn").fadeIn("fast"); //ボタンがフェードインする
      } else {
          $(".nav-pagetop__topbtn").fadeOut("fast");　//ボタンがフェードアウトする
      }
      scrollHeight = $(document).height(); //ドキュメントの高さ 
      scrollPosition = $(window).height() + $(window).scrollTop(); //現在地 
      footHeight = $("footer").innerHeight(); //止めたい位置の高さ(今回はfooter)
      if (scrollHeight - scrollPosition <= footHeight) { //ドキュメントの高さと現在地の差がfooterの高さ以下の時
          $(".nav-pagetop__topbtn").css({
              "position": "absolute", //pisitionをabsoluteに変更
          });
      } else { //それ以外の場合は
          $(".nav-pagetop__topbtn").css({
              "position": "fixed", //固定表示
          });
      }
  });


//スムーススクロール設定
  $('.nav-pagetop__topbtn').click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 200);　//スムーススクロールの速度
      return false;
  });
});

/*
* 詳細ページ　タブの切り替え
* -------------------------------------------------------------------
*/
$(document).ready(function() {
	$('.tabs_default').tabslet();
});

//事業内容タブ
////サムネイルクリックで画像切り替え
$(function () {
  $(".js-sub-img img").on("click", function () {
    img = $(this).attr("src");
    $(".js-main-img img").fadeOut(100, function () {
      $(".js-main-img img")
        .attr("src", img)
        .on("load", function () {
          $(this).fadeIn(100);
        });
    });
  });
});
/*
* 詳細ページのswiper　カルーセル
* -------------------------------------------------------------------
*/
var swiper1 = new Swiper('.detail-slider', {
  // スライドメニューの表示方法
  loop: true,
  centeredSlides: true,
  //slidesOffsetBefore: 50,
	//slidesOffsetAfter: 50,
  spaceBetween: 10,
  slidesPerView: 1,
  // 「次へ」や「戻る」のアイコン表示
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  autoplay: {
		delay: 5000,
		stopOnLastSlide: false,
		disableOnInteraction: false,
		reverseDirection: false
	},
  breakpoints: {
    // タブレット以上の場合
    520: {
      slidesPerView: 2.3,
      spaceBetween: 15,
    },
    // PC以上の場合
    960: {
      slidesPerView: 3.5,
      spaceBetween: 20,
    }
  }
});


//フッターの高さ分だけスマホの下部固定メニューをを下げる
$(function() {
  if (window.matchMedia('(max-width: 519px)').matches) {
  var height=$("#sp-fixed-menu").height();
  $("#footer").css("margin-bottom", height -1 );
  }
});

/*
* 検索ページの都道府県のアコーディオン
* -------------------------------------------------------------------
*/
var mediaQuery = matchMedia('(max-width: 519px)');
// ページが読み込まれた時に実行
handle(mediaQuery);
// ウィンドウサイズが変更されても実行されるように
mediaQuery.addListener(handle);
function handle(mq) {
  if (mq.matches) {
    // ウィンドウサイズが519px以下のとき
    $('.toggle_title').click(function () {
      $(this).toggleClass('selected');
      /*クリックでコンテンツを開閉*/
      $(this).next().slideToggle();
      /*矢印の向きを変更*/
      $(this).toggleClass('open', 200);
    });
  }
}
/*
* #のスムーススクロール //詳細ページのタブは除外
* -------------------------------------------------------------------
*/
$(function() {
  $('a[href^="#"]:not(a.noscroll)').click(function(){
   var speed = 500;
   var href= $(this).attr("href");
   var target = $(href == "#" || href == "" ? 'html' : href);
   var position = target.offset().top;
   $("html, body").animate({scrollTop:position}, speed, "swing");
   return false;
  });
});

/*
* 検索結果ページのタブレット以上時表示のアコーディオン
* -------------------------------------------------------------------
*/
$(function () {
  $('.result-toggle-title').click(function () {
    $(this).toggleClass('selected');
    /*クリックでコンテンツを開閉*/
    $(this).next().slideToggle();
    /*矢印の向きを変更*/
    $(this).toggleClass('open', 200);
  });
});

/*
* 詳細ページのスケジュールの初期位置調整
* -------------------------------------------------------------------
*/
document.addEventListener("turbolinks:load", function(){
  $("#Schdule_scroller").scrollTop(385);
})
  
/*
* 仮予約申し込みフォームのカレンダー
* -------------------------------------------------------------------
*/
$(function() {
  var data = $(able_days_times).val().slice(1,-1).replace(/\s+/g, "");
  data_ary = data.split(',');
  
  
  if($(available).val() != "0"){
    // 各曜日の時刻の範囲設定　0（日曜日）から6（土曜日）
    var logic = function( currentDateTime ){
      var today = new Date();

      if(currentDateTime == String(today)){
        this.setOptions({
          maxTime:'0:00'
        });
      }
      // 'this' is jquery object datetimepicker
      else if( currentDateTime.getDay() == 0){
        // 設定された終わり時間を0.5時間ずらす(1時間だと23時が表示されないため)
        var maxTime_num = Number(data_ary[1]) + 0.5;
        var maxTime_str = String(maxTime_num);
        console.log(maxTime_num);
        console.log(maxTime_str);
        this.setOptions({
          minTime: data_ary[0],
          maxTime: maxTime_str
        });
      }else if(currentDateTime.getDay() == 1){
        var maxTime_num = Number(data_ary[3]) + 0.5;
        var maxTime_str = String(maxTime_num);
        this.setOptions({
          minTime: data_ary[2],
          maxTime: maxTime_str
        });
      }else if(currentDateTime.getDay() == 2){
        var maxTime_num = Number(data_ary[5]) + 0.5;
        var maxTime_str = String(maxTime_num);
        this.setOptions({
          minTime: data_ary[4],
          maxTime: maxTime_str
        });
      }else if(currentDateTime.getDay() == 3){
        var maxTime_num = Number(data_ary[7]) + 0.5;
        var maxTime_str = String(maxTime_num);
        this.setOptions({
          minTime: data_ary[6],
          maxTime: maxTime_str
        });
      }else if(currentDateTime.getDay() == 4){
        var maxTime_num = Number(data_ary[9]) + 0.5;
        var maxTime_str = String(maxTime_num);
        console.log(maxTime_str);
        this.setOptions({
          minTime: data_ary[8],
          maxTime: maxTime_str
        });
      }else if(currentDateTime.getDay() == 5){
        var maxTime_num = Number(data_ary[11]) + 0.5;
        var maxTime_str = String(maxTime_num);
        this.setOptions({
          minTime: data_ary[10],
          maxTime: maxTime_str
        });
      }else if(currentDateTime.getDay() == 6){
        var maxTime_num = Number(data_ary[13]) + 0.5;
        var maxTime_str = String(maxTime_num);
        this.setOptions({
          minTime: data_ary[12],
          maxTime: maxTime_str
        });
      }
    };
    
    // 日付の選択不可設定、可能なら表示　0（日曜日）から6（土曜日）
    $.datetimepicker.setLocale('ja'); // 日本語化
    var Hour = new Date().getHours();
    $('.datepicker').datetimepicker({
      step:30,
      beforeShowDay: function (date) {
        if (date.getDay() == 0) {
          if (data_ary[0] == "-1" || data_ary[1] == "-1"){
            return [false, 'ui-state-disabled'];
          }else{
            return [true, ''];
          }
        }else if (date.getDay() == 1) {
          if (data_ary[2] == "-1" || data_ary[3] == "-1"){
            return [false, 'ui-state-disabled'];
          }else{
            return [true, ''];
          }
        }else if (date.getDay() == 2) {
          if (data_ary[4] == "-1" || data_ary[5] == "-1"){
            return [false, 'ui-state-disabled'];
          }else{
            return [true, ''];
          }
        }else if (date.getDay() == 3) {
          if (data_ary[6] == "-1" || data_ary[7] == "-1"){
            return [false, 'ui-state-disabled'];
          }else{
            return [true, ''];
          }
        }else if (date.getDay() == 4) {
          if (data_ary[8] == "-1" || data_ary[9] == "-1"){
            return [false, 'ui-state-disabled'];
          }else{
            return [true, ''];
          }
        }else if (date.getDay() == 5) {
          if (data_ary[10] == "-1" || data_ary[11] == "-1"){
            return [false, 'ui-state-disabled'];
          }else{
            return [true, ''];
          }
        }else if (date.getDay() == 6) {
          if (data_ary[12] == "-1" || data_ary[13] == "-1"){
            return [false, 'ui-state-disabled'];
          }else{
            return [true, ''];
          }
        }
      },
      
      onChangeDateTime:logic,
      onShow:logic,
      minDate: '+1970/01/02', //明日以降選択
      scrollMonth: false,
      scrollTime: false,
      scrollInput: false,
      defaultDate: new Date(new Date().setHours(Hour, 0))
    });
  }else{
    $('.datepicker').datetimepicker({
      timepicker:false,
      datepicker:false
    });
  }
  
});